<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h3 class="panel-title bold">Pengumuman</h3>
        </div>
        <div class="panel-body">
          <div class="row">
            <div class="col-md-12">
              <h4>{{ detail.judul_pengumuman }}</h4>
              <p>Tanggal : {{ formatDateTime(detail.tanggal_pengumuman) }}</p>
              <p v-html="detail.isi_pengumuman"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      detail: {
        id_pengumuman: "",
        kode_pengumuman: "",
        judul_pengumuman: "",
        isi_pengumuman: "",
        tanggal_pengumuman: "",
      },
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },

  mounted() {
    const id = this.$route.params.id;
    this.detail.id_pengumuman = id;
    this.fetchData();
  },
  methods: {
    formatDateTime(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    fetchData() {
      axios
        .get("/api/pengumuman/detail/" + this.detail.id_pengumuman)
        .then((response) => {
          this.detail.id_pengumuman = response.data.data.id_pengumuman;
          this.detail.kode_pengumuman = response.data.data.kode_pengumuman;
          this.detail.judul_pengumuman = response.data.data.judul_pengumuman;
          this.detail.isi_pengumuman = response.data.data.isi_pengumuman;
          this.detail.tanggal_pengumuman =
            response.data.data.tanggal_pengumuman;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>

