var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"panel panel-primary"},[_vm._m(0),_c('form',{attrs:{"id":"formPengumuman","enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.postData.apply(null, arguments)}}},[_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.id_pengumuman),expression:"form.id_pengumuman"}],staticClass:"form-control m-b-5",attrs:{"type":"text","hidden":"","name":"id_pengumuman"},domProps:{"value":(_vm.form.id_pengumuman)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "id_pengumuman", $event.target.value)}}}),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"suku"}},[_vm._v("Suku")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.suku),expression:"form.suku"}],staticClass:"form-control m-b-5",class:{
                      'is-invalid': _vm.formValidate.suku && !_vm.form.suku,
                    },attrs:{"name":"suku"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "suku", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiSuku),function(suku){return _c('option',{key:suku.nama_suku,domProps:{"value":suku.nama_suku}},[_vm._v(" "+_vm._s(suku.nama_suku)+" ")])}),_c('option',{attrs:{"value":"Lain-Lain"}},[_vm._v("Lain-Lain")])],2),(_vm.formValidate.suku && !_vm.form.suku)?_c('div',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.suku)}}):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"title_birthday"}},[_vm._v("Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title_birthday),expression:"form.title_birthday"}],staticClass:"form-control m-b-5",class:{
                      'is-invalid':
                        _vm.formValidate.title_birthday && !_vm.form.title_birthday,
                    },attrs:{"type":"text","name":"title_birthday"},domProps:{"value":(_vm.form.title_birthday)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "title_birthday", $event.target.value)}}}),(_vm.formValidate.title_birthday && !_vm.form.title_birthday)?_c('div',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.title_birthday)}}):_vm._e()])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"text_birthday"}},[_vm._v("Text")]),_c('quill-editor',{staticClass:"m-b-5",class:{
                      editorError:
                        _vm.formValidate.text_birthday && !_vm.form.text_birthday,
                    },style:(_vm.editorStyles),attrs:{"options":_vm.editorOption},model:{value:(_vm.form.text_birthday),callback:function ($$v) {_vm.$set(_vm.form, "text_birthday", $$v)},expression:"form.text_birthday"}}),(_vm.formValidate.text_birthday && !_vm.form.text_birthday)?_c('div',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.text_birthday)}}):_vm._e()],1)])])]),_c('div',{staticClass:"panel-footer"},[_c('button',{staticClass:"btn btn-success mt-4",attrs:{"type":"button","disabled":this.loading},on:{"click":function($event){return _vm.postData()}}},[(this.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$route.params.id ? "Update" : "Submit")+" ")])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("Form")])])
}]

export { render, staticRenderFns }