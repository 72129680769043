<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title bold">Leaderboard</h3>
          <div class="panel-heading-btn">
            <a
              href="javascript:;"
              class="btn btn-xs btn-icon btn-circle btn-success"
              v-on:click="fetchData()"
              ><i class="fa fa-redo"></i
            ></a>
          </div>
        </div>
        <div class="panel-body">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :lineNumbers="true"
            :search-options="{
              enabled: true,
              placeholder: 'Search this table',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 50,
              perPageDropdownEnabled: false,
              position: 'bottom',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }"
          >
            <div slot="table-actions"></div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <router-link
                  :to="'/leaderboard/detail/' + props.row.nomor_identitas"
                  class="btn btn-sm btn-success m-r-5"
                >
                  <i class="fa fa-search"></i>
                </router-link>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import Swal from "sweetalert2";

export default {
  data() {
    return {
      columns: [
        {
          label: "Nama",
          field: "nama_lengkap",
          width: "210px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Kabupaten/Kota",
          field: "kabupaten",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Jumlah Referal",
          field: "agregat_referal",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "150px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      form: {
        level: "",
      },
      link: "/api/leaderboard/",
      nik: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get(this.link)
        .then((response) => {
          // console.log(response.data.data);
          this.rows = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>