import axios from "axios";
import Swal from "sweetalert2";

axios.interceptors.request.use((request) => {
  request["baseURL"] = "https://api-hub.kawanasta.com/";
  // request["baseURL"] = "http://localhost/api/kawan-asta";

  return request;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status, data } = error.response;

    if (status >= 500) {
      Swal.fire({
        icon: "error",
        title: "",
        text: data.message,
      });
    }

    return Promise.reject(error);
  }
);
