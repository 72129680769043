<script>
const sidebarMenu = [
  { path: "/dashboard", icon: "fa fa-th", title: "Dashboard" },
  { path: "/profil", icon: "fa fa-user", title: "Profil" },
  { path: "/pendukung", icon: "fa fa-users", title: "Pendukung" },
  // { path: "/tiket", icon: "fa fa-ticket-alt", title: "Tiket" },
  {
    path: "/tiket",
    icon: "fa fa-ticket-alt",
    title: "Aspirasi Pendukung",
  },
  {
    path: "/tiket",
    icon: "fa fa-ticket-alt",
    title: "Sampaikan Aspirasi",
  },
  { path: "/pengumuman", icon: "fa fa-bullhorn", title: "Pengumuman" },
  { path: "/birthday-text", icon: "fa fa-gift", title: "Birthday Text" },
  { path: "/leaderboard", icon: "fa fa-sort-numeric-up", title: "Leaderboard" },
];

export default sidebarMenu;
</script>
