<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel panel-primary">
          <div class="panel-heading">
            <h3 class="panel-title bold">Formulir Aspirasi Konstituen</h3>
          </div>
          <form
            @submit.prevent="postData"
            id="formPeserta"
            enctype="multipart/form-data"
          >
            <div class="panel-body">
              <div class="row" v-if="level == 'User'">
                <div class="col-md-12">
                  <b-alert variant="info" show fade>
                    Sampaikan aspirasi, pendapat, saran, kritik maupun laporan
                    Anda langsung ke Asri Tadda dengan mengisi formulir berikut.
                  </b-alert>
                </div>
              </div>

              <div class="row">
                <input
                  v-model="form.id_tiket"
                  type="text"
                  hidden
                  name="id_tiket"
                  class="form-control m-b-5"
                />
                <input
                  v-model="form.file_lama"
                  type="text"
                  hidden
                  name="file_lama"
                  class="form-control m-b-5"
                />
                <input
                  v-model="form.nik"
                  type="text"
                  hidden
                  name="nik"
                  class="form-control m-b-5"
                />

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="subjek">Judul Aspirasi</label>
                    <input
                      v-model="form.subjek"
                      type="text"
                      name="subjek"
                      class="form-control m-b-5"
                      :class="{
                        'is-invalid': formValidate.subjek && !form.subjek,
                      }"
                    />
                    <div
                      class="text-danger"
                      v-if="formValidate.subjek && !form.subjek"
                      v-html="formValidate.subjek"
                    ></div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="pesan" v-if="level == 'User'"
                      >Detail Aspirasi Anda</label
                    >
                    <label for="pesan" v-else>Detail Aspirasi </label>
                    <quill-editor
                      v-model="form.pesan"
                      :options="editorOption"
                      class="m-b-5"
                      :style="editorStyles"
                      :class="{
                        editorError: formValidate.pesan && !form.pesan,
                      }"
                    ></quill-editor>
                    <div
                      class="text-danger"
                      v-if="formValidate.pesan && !form.pesan"
                      v-html="formValidate.pesan"
                    ></div>
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: 60px">
                  <div class="form-group">
                    <label for=""
                      >Sertakan dokumen, foto atau gambar yang mendukung
                      aspirasi Anda.</label
                    >
                    <br />
                    <input
                      type="file"
                      name="file_dokumen"
                      accept="image/*"
                      class="btn btn-success"
                      ref="fileInput"
                      @change="onFileChange"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="panel-footer">
              <button
                type="button"
                class="btn btn-success mt-4"
                @click="postData()"
                :disabled="this.loading"
              >
                <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
                {{ $route.params.id ? "Update" : "KIRIM ASPIRASI" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.editorError {
  border: 0.5px solid #ff5b57;
}
</style>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      editorStyles: {
        height: "200px",
        marginBottom: "100px",
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline"], // Teks tebal, miring, garis bawah, coret
            // ["link", "image", "video"], // Tautan, gambar, video
            [{ list: "ordered" }, { list: "bullet" }], // Daftar terurut, daftar tak terurut
            ["blockquote"], // Kutipan blok, blok kode
            [{ header: 2 }], // Heading 1, Heading 2
            // [{ color: [] }, { background: [] }], // Warna teks, latar belakang teks
            [{ align: [] }], // Penjajaran teks
            ["clean"], // Membersihkan format
          ],
        },
        placeholder: "Masukan aspirasi anda di sini...",
      },
      form: {
        id_tiket: "",
        file_lama: "",
        subjek: "",
        pesan: "",
        nik: "",
      },
      file: "",
      link: "/api/tiket/create",
      formValidate: [],
      loading: false,
    };
  },
  created() {
    const user = localStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user);
      this.level = this.user.level;
    } else {
      // this.$router.push("/login");
    }
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.form.id_tiket = id;
      this.link = "/api/tiket/update";
      this.fetchData();
    } else {
      this.form.id_tiket = "";
    }
    this.fetchUser();
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      this.file = file;
    },
    postData() {
      // this.loading = true;
      const formData = new FormData();

      formData.append("subjek", this.form.subjek);
      formData.append("pesan", this.form.pesan);
      formData.append("nik", this.form.nik);
      formData.append("file_dokumen", this.file);

      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              this.$router.push("/tiket");
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchUser() {
      const user = localStorage.getItem("user");
      if (user) {
        this.user = JSON.parse(user);
        this.form.nik = this.user.nik;
      } else {
        //
      }
    },
    fetchData() {
      axios
        .get("/api/tiket/detail/" + this.form.id_tiket)
        .then((response) => {
          this.form.id_tiket = response.data.data.id_tiket;
          this.form.file_lama = response.data.data.file_lama;
          this.form.subjek = response.data.data.subjek;
          this.form.pesan = response.data.data.pesan;
          this.form.nik = response.data.data.created_by;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>

