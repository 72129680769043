<template>
  <!-- begin register -->
  <div class="register register-with-news-feed">
    <!-- begin news-feed -->
    <div class="news-feed">
      <div
        class="news-image"
        style="background-image: url(/assets/img/login.jpg)"
      ></div>
      <!-- <div class="news-caption">
        <h4 class="caption-title">Kawan <b>Asta</b></h4>
        <p>Mari bergabung</p>
      </div> -->
    </div>
    <!-- end news-feed -->
    <!-- begin right-content -->
    <div class="right-content">
      <div class="login-header">
        <div class="brand">
          <img src="/assets/img/logo-text.png" alt="Logo" style="width: 90%" />
        </div>
      </div>
      <!-- begin register-header -->
      <h1 class="register-header">
        <small>Mohon mengisi data anda dengan baik dan benar.</small>
      </h1>
      <!-- end register-header -->
      <!-- begin register-content -->
      <div class="register-content">
        <form
          @submit.prevent="postData"
          id="formPeserta"
          enctype="multipart/form-data"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="nama_lengkap"
                  >Nama Lengkap <span class="text-danger">*</span></label
                >
                <input
                  v-model="form.nama_lengkap"
                  type="text"
                  name="nama_lengkap"
                  class="form-control m-b-5"
                  :class="{
                    'is-invalid': formValidate.nama_lengkap,
                  }"
                  placeholder="Nama Lengkap"
                />
                <small
                  class="text-danger"
                  v-html="formValidate.nama_lengkap"
                ></small>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label for="email_pendukung"
                  >Email <span class="text-danger">*</span></label
                >
                <input
                  v-model="form.email_pendukung"
                  type="email"
                  name="email_pendukung"
                  class="form-control m-b-5"
                  :class="{
                    'is-invalid': formValidate.email_pendukung,
                  }"
                  placeholder="Alamat Email"
                />
                <small
                  >Bila tak ada email, bisa menggunakan kombinasi
                  NIK@kawanasta.com, Contoh :
                  7371011001010001@kawanasta.com</small
                >
                <small
                  class="text-danger"
                  v-html="formValidate.email_pendukung"
                ></small>
              </div>
            </div>

            <div class="col-md-8">
              <div class="form-group">
                <label for="nomor_identitas"
                  >Nomor Induk Kependudukan (NIK)
                  <span class="text-danger">*</span></label
                >
                <masked-input
                  v-model="form.nomor_identitas"
                  type="text"
                  name="nomor_identitas"
                  class="form-control m-b-5"
                  mask="1111111111111111"
                  :class="{
                    'is-invalid': formValidate.nomor_identitas,
                  }"
                  placeholder="Nomor Induk Kependudukan (NIK)"
                ></masked-input>
                <small
                  class="text-danger"
                  v-html="formValidate.nomor_identitas"
                ></small>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label"
                  >Nomor WA <span class="text-danger">*</span></label
                >
                <masked-input
                  v-model="form.no_handphone"
                  type="text"
                  name="no_handphone"
                  class="form-control m-b-5"
                  mask="1111-1111-1111"
                  :class="{
                    'is-invalid': formValidate.no_handphone,
                  }"
                  placeholder="xxxx-xxxx-xxxx"
                ></masked-input>
                <small
                  class="text-danger"
                  v-html="formValidate.no_handphone"
                ></small>
              </div>
            </div>

            <div class="col-md-4 col-xs-6">
              <div class="form-group">
                <label for="kabupaten"
                  >Kabupaten <span class="text-danger">*</span></label
                >
                <select
                  v-model="form.kabupaten"
                  name="kabupaten"
                  class="form-control m-b-5"
                  v-on:change="getKecamatan($event)"
                  :class="{
                    'is-invalid': formValidate.kabupaten,
                  }"
                >
                  <option value="">Pilih</option>
                  <option
                    v-for="kabupaten in opsiKabupaten"
                    :key="kabupaten.nama_kabupaten"
                    :value="kabupaten.nama_kabupaten"
                  >
                    {{ kabupaten.nama_kabupaten }}
                  </option>
                </select>
                <small
                  class="text-danger"
                  v-html="formValidate.kabupaten"
                ></small>
              </div>
            </div>

            <div class="col-md-4 col-xs-6">
              <div class="form-group">
                <label for="kecamatan"
                  >Kecamatan <span class="text-danger">*</span></label
                >
                <select
                  v-model="form.kecamatan"
                  name="kecamatan"
                  class="form-control m-b-5"
                  :class="{
                    'is-invalid': formValidate.kecamatan,
                  }"
                  v-on:change="getKelurahan($event)"
                >
                  <option value="">Pilih</option>
                  <option
                    v-for="kecamatan in opsiKecamatan"
                    :key="kecamatan.nama_kecamatan"
                    :value="kecamatan.nama_kecamatan"
                  >
                    {{ kecamatan.nama_kecamatan }}
                  </option>
                </select>
                <small
                  class="text-danger"
                  v-html="formValidate.kecamatan"
                ></small>
              </div>
            </div>

            <div class="col-md-4 col-xs-6">
              <div class="form-group">
                <label for="kelurahan"
                  >Kelurahan/Desa <span class="text-danger">*</span></label
                >
                <select
                  v-model="form.kelurahan"
                  name="kelurahan"
                  class="form-control m-b-5"
                  :class="{
                    'is-invalid': formValidate.kelurahan,
                  }"
                >
                  <option value="">Pilih</option>
                  <option
                    v-for="kelurahan in opsiKelurahan"
                    :key="kelurahan.nama_kelurahan"
                    :value="kelurahan.nama_kelurahan"
                  >
                    {{ kelurahan.nama_kelurahan }}
                  </option>
                </select>
                <small
                  class="text-danger"
                  v-html="formValidate.kelurahan"
                ></small>
              </div>
            </div>

            <div class="col-md-4 col-xs-6">
              <div class="form-group">
                <label for="rw">RW/Dusun</label>
                <input
                  v-model="form.rw"
                  type="text"
                  name="rw"
                  class="form-control m-b-5"
                  :class="{
                    'is-invalid': formValidate.rw,
                  }"
                  placeholder="Contoh: 001"
                />
                <small class="text-danger" v-html="formValidate.rw"></small>
              </div>
            </div>

            <div class="col-md-4 col-xs-6">
              <div class="form-group">
                <label for="tps">TPS</label>
                <input
                  v-model="form.tps"
                  type="text"
                  name="tps"
                  class="form-control m-b-5"
                  :class="{
                    'is-invalid': formValidate.tps,
                  }"
                  placeholder="Contoh: 001"
                />
                <small class="text-danger" v-html="formValidate.tps"></small>
              </div>
            </div>
            <div class="col-md-4 col-xs-6">
              <div class="form-group">
                <label for="suku"
                  >Suku <span class="text-danger">*</span></label
                >
                <select
                  v-model="form.suku"
                  name="suku"
                  class="form-control m-b-5"
                  :class="{
                    'is-invalid': formValidate.suku,
                  }"
                >
                  <option value="">Pilih</option>
                  <option
                    v-for="suku in opsiSuku"
                    :key="suku.nama_suku"
                    :value="suku.nama_suku"
                  >
                    {{ suku.nama_suku }}
                  </option>
                  <option value="Lain-Lain">Lain-Lain</option>
                </select>
                <small class="text-danger" v-html="formValidate.suku"></small>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="tgl_lahir"
                  >Tanggal Lahir <span class="text-danger">*</span></label
                >
                <input
                  type="date"
                  v-model="form.tgl_lahir"
                  class="form-control"
                  placeholder="Tanggal Lahir"
                  :class="{
                    'is-invalid': formValidate.tgl_lahir,
                  }"
                />
                <small
                  class="text-danger"
                  v-html="formValidate.tgl_lahir"
                ></small>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="pekerjaan"
                  >Pekerjaan <span class="text-danger">*</span></label
                >
                <select
                  v-model="form.pekerjaan"
                  name="pekerjaan"
                  class="form-control m-b-5"
                  :class="{
                    'is-invalid': formValidate.pekerjaan,
                  }"
                >
                  <option value="">Pilih</option>
                  <option value="Pelajar">Pelajar/Mahasiswa</option>
                  <option value="Karyawan Swasta">Karyawan Swasta</option>
                  <option value="PNS">PNS</option>
                  <option value="Nelayan">Nelayan/Petani</option>
                  <option value="Lain-Lain">Lain-Lain</option>
                </select>
                <small
                  class="text-danger"
                  v-html="formValidate.pekerjaan"
                ></small>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="password"
                  >Password <span class="text-danger">*</span></label
                >
                <input
                  type="password"
                  v-model="form.password"
                  class="form-control"
                  placeholder="Password"
                  :class="{
                    'is-invalid': formValidate.password,
                  }"
                />
                <small
                  class="text-danger"
                  v-html="formValidate.password"
                ></small>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="confirm_password"
                  >Ulangi Password <span class="text-danger">*</span></label
                >
                <input
                  type="password"
                  v-model="form.confirm_password"
                  class="form-control"
                  placeholder="Ulangi Password"
                  :class="{
                    'is-invalid': formValidate.confirm_password,
                  }"
                />
                <small
                  class="text-danger"
                  v-html="formValidate.confirm_password"
                ></small>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="referal">Kode Referal</label>
                <input
                  type="number"
                  v-model="form.referal"
                  class="form-control"
                  placeholder="Kode Referal"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="foto">Foto</label>
                <div>
                  <a
                    href="#"
                    class="btn btn-block btn-primary m-1"
                    role="button"
                    @click.prevent="showFileChooser"
                  >
                    <i class="fa fa-fw fa-image"></i> Pilih/Ambil Foto
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <input
                style="display: none"
                ref="input"
                type="file"
                name="image"
                accept="image/*"
                @change="setImage"
              />

              <div class="row">
                <div class="col-md-12 cropper-area">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="1 / 1"
                      :src="imgSrc"
                      preview=".preview"
                      style="width: 100%; border-radius: 5px; overflow: hidden"
                    />
                    <div v-if="this.imgSrc">
                      <div class="btn-group mt-1">
                        <a
                          href="#"
                          class="btn btn-sm btn-primary"
                          role="button"
                          @click.prevent="rotate(90)"
                        >
                          <i class="fa fa-redo"></i>
                        </a>
                        <a
                          href="#"
                          class="btn btn-sm btn-primary"
                          role="button"
                          @click.prevent="rotate(-90)"
                        >
                          <i class="fa fa-redo fa-flip-horizontal"></i>
                        </a>
                        <a
                          href="#"
                          class="btn btn-sm btn-primary"
                          role="button"
                          @click.prevent="cropImage"
                        >
                          <i class="fa fa-crop"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-4 cropped-image p-4">
              <img
                v-if="cropImg"
                :src="cropImg"
                alt="Cropped Image"
                style="
                  width: 100%;
                  border-radius: 50%;
                  border: solid 2px #348fe2;
                "
              />
              <div v-else class="crop-placeholder" />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="checkbox checkbox-css">
                <input
                  type="checkbox"
                  id="tim_relawan_tps"
                  name="tim_relawan_tps"
                  v-model="form.tim_relawan_tps"
                />
                <label for="tim_relawan_tps"
                  >Saya bersedia mencoblos Asri Tadda dan menjadi Saksi di TPS
                  (Tempat Pemungutan Suara)</label
                >
              </div>
            </div>
          </div>

          <div class="register-buttons m-t-30">
            <button
              type="button"
              class="btn btn-danger btn-block btn-lg"
              @click="postData()"
              :disabled="this.loading"
            >
              <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
              REGISTRASI
            </button>
          </div>
          <div
            class="m-t-20 m-b-20 p-b-20 text-inverse"
            style="font-size: 14px"
          >
            Sudah memiliki akun? klik
            <router-link to="/login/">Login</router-link> untuk login.
          </div>
          <hr />
          <p class="text-center">&copy; Kawan Asta All Right Reserved 2023</p>
        </form>
      </div>
      <!-- end register-content -->
    </div>
    <!-- end right-content -->
  </div>
  <!-- end register -->
</template>

<script>
import PageOptions from "../config/PageOptions.vue";
import axios from "axios";
import Swal from "sweetalert2";
import MaskedInput from "vue-masked-input";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  components: {
    MaskedInput,
    VueCropper,
  },
  data() {
    return {
      form: {
        nama_lengkap: "",
        nama_panggilan: "",
        no_handphone: "",
        email_pendukung: "",
        nomor_identitas: "",
        kabupaten: "",
        kecamatan: "",
        kelurahan: "",
        rw: "",
        tps: "",
        suku: "",
        password: "",
        confirm_password: "",
        tim_relawan_tps: false,
        foto: "",
        foto_lama: "",
        foto_crop: "",
        referal: "",
        pekerjaan: "",
        tgl_lahir: "",
      },
      opsiSuku: [],
      opsiKabupaten: [],
      opsiKecamatan: [],
      opsiKelurahan: [],
      imgSrc: "",
      cropImg: "",
      formValidate: [],
      loading: false,
    };
  },
  mounted() {
    this.getKabupaten();
    this.getSuku();
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    postData() {
      this.loading = true;
      let nik = this.form.nomor_identitas;
      this.form.nomor_identitas = nik.split("_").join("");
      this.loading = true;
      let no_handphone = this.form.no_handphone;
      no_handphone = no_handphone.split("_").join("");
      this.form.no_handphone = no_handphone.split("-").join("");
      const formData = new FormData();

      if (this.cropImg.includes("http") || this.cropImg == "") {
        // formData.append("foto", "");
      } else {
        const blob = this.dataURLtoBlob(this.cropImg);
        formData.append("foto", blob, "foto.png");
      }
      formData.append("nama_lengkap", this.form.nama_lengkap);
      formData.append("nama_panggilan", this.form.nama_panggilan);
      formData.append("no_handphone", this.form.no_handphone);
      formData.append("email_pendukung", this.form.email_pendukung);
      formData.append("nomor_identitas", this.form.nomor_identitas);
      formData.append("kabupaten", this.form.kabupaten);
      formData.append("kecamatan", this.form.kecamatan);
      formData.append("kelurahan", this.form.kelurahan);
      formData.append("rw", this.form.rw);
      formData.append("tps", this.form.tps);
      formData.append("suku", this.form.suku);
      formData.append("tim_relawan_tps", this.form.tim_relawan_tps);
      formData.append("foto_lama", this.form.foto_lama);
      formData.append("password", this.form.password);
      formData.append("confirm_password", this.form.confirm_password);
      formData.append("referal", this.form.referal);
      formData.append("pekerjaan", this.form.pekerjaan);
      formData.append("tgl_lahir", this.form.tgl_lahir);

      // console.log(formData.get("foto"));
      axios
        .post("/api/auth/registrasi/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            // console.log(response.data.message);
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.$router.push("/login");
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getSuku() {
      axios
        .get("/api/suku")
        .then((response) => {
          this.opsiSuku = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKabupaten() {
      axios
        .get("/api/daerah/kabupaten")
        .then((response) => {
          this.opsiKabupaten = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKecamatan(event) {
      this.form.kabupaten = event.target.value;
      axios
        .get("/api/daerah/kecamatan/" + event.target.value)
        .then((response) => {
          this.opsiKecamatan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKelurahan(event) {
      this.form.kecamatan = event.target.value;
      axios
        .get("/api/daerah/kelurahan/" + event.target.value)
        .then((response) => {
          this.opsiKelurahan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    dataURLtoBlob(dataURL) {
      const base64Data = dataURL.split(",")[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: "image/png" });
    },

    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },

    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
  },
};
</script>