<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title bold">Daftar Pendukung</h3>
          <div class="panel-heading-btn">
            <a
              href="javascript:;"
              class="btn btn-xs btn-icon btn-circle btn-success"
              v-on:click="fetchData()"
              ><i class="fa fa-redo"></i
            ></a>
          </div>
        </div>
        <div class="panel-body">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :lineNumbers="true"
            :search-options="{
              enabled: true,
              placeholder: 'Search this table',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
              perPageDropdownEnabled: false,
              position: 'bottom',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }"
          >
            <div slot="table-actions">
              <div style="display: flex">
                <button
                  type="button"
                  v-on:click="downloadExcel()"
                  class="btn btn-success mr-2"
                >
                  <i class="fa fa-file-excel d-md-none"></i>
                  <span class="d-none d-md-block">Export Excel</span>
                </button>
                <router-link to="/pendukung/create" class="btn btn-primary">
                  <i class="fa fa-plus d-md-none"></i>
                  <span class="d-none d-md-block">Tambah Pendukung</span>
                </router-link>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'nama'">
                <router-link
                  :to="'/pendukung/detail/' + props.row.nomor_identitas"
                >
                  {{ props.row.nama_lengkap }}
                </router-link>
              </span>

              <span v-if="props.column.field == 'status'">
                <div class="switcher" v-if="isAdmin">
                  <input
                    type="checkbox"
                    :name="props.row.id_pendukung"
                    :id="props.row.id_pendukung"
                    :checked="parseInt(props.row.status_aktif)"
                    v-on:click="
                      toggleUser(props.row.id_pendukung, props.row.status_aktif)
                    "
                  />
                  <label :for="props.row.id_pendukung"></label>
                </div>
                <div v-if="!isAdmin">
                  <div class="switcher" v-if="props.row.level !== 'Admin'">
                    <input
                      type="checkbox"
                      :name="props.row.id_pendukung"
                      :id="props.row.id_pendukung"
                      :checked="parseInt(props.row.status_aktif)"
                      v-on:click="
                        toggleUser(
                          props.row.id_pendukung,
                          props.row.status_aktif
                        )
                      "
                    />
                    <label :for="props.row.id_pendukung"></label>
                  </div>
                </div>
              </span>
              <span v-if="props.column.field == 'action'">
                <div v-if="isAdmin">
                  <router-link
                    :to="'/pendukung/update/' + props.row.id_pendukung"
                    class="btn btn-sm btn-warning m-r-5"
                  >
                    <i class="fa fa-pencil-alt"></i>
                  </router-link>
                  <button
                    class="btn btn-sm btn-info m-r-5"
                    v-on:click="confirmCard(props.row.id_pendukung)"
                  >
                    <i class="fa fa-address-card"></i>
                  </button>
                  <button
                    class="btn btn-sm btn-danger"
                    v-on:click="confirmDelete(props.row.id_pendukung)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
                <div v-if="!isAdmin">
                  <router-link
                    :to="'/pendukung/update/' + props.row.id_pendukung"
                    class="btn btn-sm btn-warning m-r-5"
                    v-if="props.row.level !== 'Admin'"
                  >
                    <i class="fa fa-pencil-alt"></i>
                  </router-link>
                  <button
                    class="btn btn-sm btn-info m-r-5"
                    v-on:click="confirmCard(props.row.id_pendukung)"
                    v-if="props.row.level !== 'Admin'"
                  >
                    <i class="fa fa-address-card"></i>
                  </button>
                  <button
                    class="btn btn-sm btn-danger"
                    v-on:click="confirmDelete(props.row.id_pendukung)"
                    v-if="props.row.level !== 'Admin'"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
const ExcelJS = require("exceljs");

export default {
  data() {
    return {
      columns: [
        {
          label: "Nama",
          field: "nama",
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "NIK",
          field: "nomor_identitas",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "No. HP",
          field: "no_handphone",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Kabupaten",
          field: "kabupaten",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Kecamatan",
          field: "kecamatan",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Kelurahan/Desa",
          field: "kelurahan",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Suku",
          field: "suku",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Status Login",
          field: "status",
          width: "100px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "150px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      isAdmin: true,
    };
  },
  created() {
    const user = localStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user);
      this.levelUser = this.user.level;
      if (this.levelUser !== "Admin") {
        this.isAdmin = false;
      }
    } else {
      // this.$router.push("/login");
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      // this.rows = [];
      axios
        .get("/api/pendukung")
        .then((response) => {
          // console.log(response.data.data);
          this.rows = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    editRow(id) {
      window.alert(id);
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/api/pendukung/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
      // window.alert("Hapus Data dengan id: " + id);
    },

    confirmCard(id) {
      Swal.fire({
        title: "Apakah Anda Akan Mengunduh Kartu Anggota ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya!",
      }).then((result) => {
        if (result.value) {
          this.downloadCard(id);
        }
      });
    },
    downloadCard(id) {
      try {
        axios
          .get("/api/profil/card/" + id)
          .then((response) => {
            if (response.status === 200) {
              const imageUrl = response.data.card;
              fetch(imageUrl)
                .then((response) => response.blob())
                .then((blob) => {
                  const url = window.URL.createObjectURL(new Blob([blob]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "kartu_anggota.png");
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    toggleUser(id, status) {
      var toggleLink = "/api/pendukung/toggleOn/" + id;
      console.log(status);
      if (status == 1) {
        toggleLink = "/api/pendukung/toggleOff/" + id;
      }
      try {
        axios
          .get(toggleLink)
          .then((response) => {
            console.log(response.status);
            if (response.status === 201) {
              this.fetchData();
              // this.isChecked = true;
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    downloadExcel() {
      axios
        .get("/api/pendukung")
        .then((response) => {
          let data = response.data.data;

          let dataArray = data.map((item) => ({
            "Nomor Urut": item.no_pendukung || " ",
            Nama: item.nama_lengkap || " ",
            NIK: item.nomor_identitas || " ",
            Email: item.email_pendukung || " ",
            "No. HP": item.no_handphone || " ",
            Kabupaten: item.kabupaten || " ",
            Kecamatan: item.kecamatan || " ",
            "Kelurahan/Desa": item.kelurahan || " ",
            "RW/Dusun": item.rw || " ",
            TPS: item.tps || " ",
            Suku: item.suku || " ",
            Pekerjaan: item.pekerjaan || " ",
          }));

          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Data Pendukung");

          const columnWidths = [
            { header: "Nomor Urut", key: "Nomor Urut", width: 12 },
            { header: "Nama", key: "Nama", width: 30 },
            { header: "NIK", key: "NIK", width: 18 },
            { header: "Email", key: "Email", width: 28 },
            { header: "No. HP", key: "No. HP", width: 17 },
            { header: "Kabupaten", key: "Kabupaten", width: 16 },
            { header: "Kecamatan", key: "Kecamatan", width: 16 },
            { header: "Kelurahan/Desa", key: "Kelurahan/Desa", width: 16 },
            { header: "RW/Dusun", key: "RW/Dusun", width: 10 },
            { header: "TPS", key: "TPS", width: 6 },
            { header: "Suku", key: "Suku", width: 10 },
            { header: "Referal", key: "Referal", width: 10 },
          ];

          worksheet.columns = columnWidths;

          // Mengisi data ke dalam worksheet seperti yang sudah Anda cantumkan
          dataArray.forEach((item) => {
            worksheet.addRow(item);
          });

          // Mengatur header dengan font bold dan rata tengah (centered)
          const headerRow = worksheet.getRow(1);
          headerRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = { horizontal: "center" };
          });

          // Mengatur border pada sel-sel di worksheet
          const borderStyle = {
            style: "thin",
            color: { argb: "000000" }, // Hitam
          };

          worksheet.eachRow((row) => {
            row.eachCell((cell) => {
              cell.border = {
                top: borderStyle,
                left: borderStyle,
                bottom: borderStyle,
                right: borderStyle,
              };
            });
          });

          // Mengkonversi workbook menjadi bentuk binary (buffer)
          return workbook.xlsx.writeBuffer();
        })
        .then((excelBuffer) => {
          // Mengunduh file Excel
          this.saveAsExcelFile(excelBuffer, "data-pendukung.xlsx");
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    saveAsExcelFile(buffer, filename) {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      if (navigator.msSaveBlob) {
        // Untuk Internet Explorer atau Edge
        navigator.msSaveBlob(blob, filename);
      } else {
        // Untuk browser modern
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      }
    },
  },
};
</script>