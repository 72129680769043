<template>
  <ul class="nav">
    <li class="nav-profile">
      <a href="javascript:;">
        <div class="cover with-shadow"></div>
        <div class="image">
          <img :src="this.foto" alt="" />
        </div>
        <div class="info">
          {{ this.nama }}
          <small>{{ this.no_anggota }}</small>
          <small>{{ this.username }}</small>
        </div>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "SidebarNavProfile",
  data() {
    return {
      foto: "/assets/img/user.png",
      level: "User",
      nama: "User",
      username: "",
      no_anggota: "",
    };
  },
  created() {
    const user = localStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user);
      this.foto = this.user.foto;
      this.level = this.user.level;
      this.nama = this.user.nama;
      this.username = this.user.username;
      this.no_anggota = this.user.no_anggota;
    } else {
      // this.$router.push("/login");
    }
  },
};
</script>
