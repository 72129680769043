<template>
  <div>
    <div class="profile">
      <div class="profile-header">
        <div class="profile-header-cover"></div>
        <div class="profile-header-content">
          <div class="profile-header-img">
            <img :src="cropImg" alt="foto" />
          </div>
          <div class="profile-header-info m-b-15">
            <h4 class="m-t-10 m-b-5">{{ this.form.nama_lengkap }}</h4>
            <p class="m-b-15">{{ this.form.email_pendukung }}</p>
            <a href="javascript:;" class="btn btn-xs btn-yellow"
              >Jumlah Referal : {{ this.referal }}</a
            >
          </div>
        </div>
        <ul class="profile-header-tab nav nav-tabs">
          <li class="nav-item">
            <a
              href="javascript:;"
              v-on:click="show('about')"
              v-bind:class="{ active: tab.about }"
              class="nav-link"
              data-toggle="tab"
              >PROFIL</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="profile-content">
      <div class="tab-content p-0">
        <div class="tab-pane fade" v-bind:class="{ 'show active': tab.about }">
          <div class="table-responsive form-inline">
            <table class="table table-profile">
              <tbody>
                <tr class="highlight">
                  <td class="field valign-middle">
                    Nomor Induk Kependudukan (NIK)
                  </td>
                  <td>{{ this.form.nomor_identitas }}</td>
                </tr>
                <tr class="divider">
                  <td colspan="2"></td>
                </tr>
                <tr>
                  <td class="field valign-middle">Nomor Anggota</td>
                  <td>
                    {{ this.form.no_pendukung }}
                  </td>
                </tr>
                <tr class="divider">
                  <td colspan="2"></td>
                </tr>
                <tr class="highlight">
                  <td class="field valign-middle">No. HP/WA</td>
                  <td>
                    <i class="fa fa-mobile fa-lg m-r-5"></i>
                    {{ this.form.no_handphone }}
                  </td>
                </tr>
                <tr class="divider">
                  <td colspan="2"></td>
                </tr>
                <tr>
                  <td class="field valign-middle">Kabupaten</td>
                  <td>{{ this.form.kabupaten }}</td>
                </tr>
                <tr>
                  <td class="field valign-middle">Kecamatan</td>
                  <td>{{ this.form.kecamatan }}</td>
                </tr>
                <tr>
                  <td class="field valign-middle">Kelurahan</td>
                  <td>{{ this.form.kelurahan }}</td>
                </tr>
                <tr>
                  <td class="field valign-middle">RW</td>
                  <td>{{ this.form.rw }}</td>
                </tr>
                <tr>
                  <td class="field valign-middle">TPS</td>
                  <td>{{ this.form.tps }}</td>
                </tr>
                <tr>
                  <td class="field valign-middle">Suku</td>
                  <td>{{ this.form.suku }}</td>
                </tr>
                <tr class="divider">
                  <td colspan="2"></td>
                </tr>
                <tr class="highlight">
                  <td class="field valign-middle">&nbsp;</td>
                  <td class="p-t-10 p-b-10">
                    <button
                      class="btn btn-sm btn-info m-r-5"
                      v-on:click="confirmCard(form.id_pendukung)"
                    >
                      <i class="fa fa-address-card"></i> Download Kartu Anggota
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      referal: 0,
      foto: "/assets/img/user.png",
      username: "",
      level: "User",
      nama: "User",
      nik: "",
      tab: {
        about: true,
        referal: false,
      },
      form: {
        id_pendukung: "",
        no_urut: "",
        no_pendukung: "",
        nama_lengkap: "",
        nama_panggilan: "",
        no_handphone: "",
        email_pendukung: "",
        nomor_identitas: "",
        kabupaten: "",
        kecamatan: "",
        kelurahan: "",
        rw: "",
        tps: "",
        suku: "",
        level: "",
        tim_relawan_tps: false,
        foto: "",
        foto_lama: "",
        foto_crop: "",
        password_lama: "",
        password_baru: "",
      },
      link: "/api/pendukung/update",
      opsiSuku: [],
      opsiKabupaten: [],
      opsiKecamatan: [],
      opsiKelurahan: [],
      imgSrc: "",
      cropImg: "/assets/img/user.png",
      formValidate: [],
      loading: false,
      modalVisible: false,
      conditionMet: false,
    };
  },
  created() {
    PageOptions.pageContentFullWidth = true;
    const id = this.$route.params.id;
    this.nik = id;
    this.fetchData();
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageContentFullWidth = false;
    next();
  },
  methods: {
    show: function (x) {
      this.tab.about = false;
      this.tab.referal = false;

      switch (x) {
        case "about":
          this.tab.about = true;
          break;
        case "referal":
          this.tab.referal = true;
          break;
        default:
          this.tab.about = true;
          break;
      }
    },
    updatePassword() {
      this.loading = true;
      const formData = new FormData();

      formData.append("id_pendukung", this.form.id_pendukung);
      formData.append("password_lama", this.form.password_lama);
      formData.append("password_baru", this.form.password_baru);
      // console.log(formData.get("foto"));
      axios
        .post("/api/auth/changePassword/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              if (response.data.status) {
                localStorage.removeItem("user");
                if (this.$route.path !== "/login") {
                  this.$router.push("/login");
                }
              }
              // this.$router.push("/profil");
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.loading = true;
      const formData = new FormData();

      if (this.cropImg.includes("http") || this.cropImg == "") {
        // formData.append("foto", "");
      } else {
        const blob = this.dataURLtoBlob(this.cropImg);
        formData.append("foto", blob, "foto.png");
      }

      formData.append("id_pendukung", this.form.id_pendukung);
      formData.append("no_urut", this.form.no_urut);
      formData.append("no_pendukung", this.form.no_pendukung);
      formData.append("nama_lengkap", this.form.nama_lengkap);
      formData.append("nama_panggilan", this.form.nama_panggilan);
      formData.append("no_handphone", this.form.no_handphone);
      formData.append("email_pendukung", this.form.email_pendukung);
      formData.append("nomor_identitas", this.form.nomor_identitas);
      formData.append("kabupaten", this.form.kabupaten);
      formData.append("kecamatan", this.form.kecamatan);
      formData.append("kelurahan", this.form.kelurahan);
      formData.append("rw", this.form.rw);
      formData.append("tps", this.form.tps);
      formData.append("suku", this.form.suku);
      formData.append("level", this.form.level);
      formData.append("tim_relawan_tps", this.form.tim_relawan_tps);
      formData.append("foto_lama", this.form.foto_lama);

      // console.log(formData.get("foto"));
      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              if (response.data.status) {
                localStorage.removeItem("user");
                if (this.$route.path !== "/login") {
                  this.$router.push("/login");
                }
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      axios
        .get("/api/profil/detail/" + this.nik)
        .then((response) => {
          this.form.id_pendukung = response.data.data.id_pendukung;
          this.form.no_urut = response.data.data.no_urut;
          this.form.no_pendukung = response.data.data.no_pendukung;
          this.form.nama_lengkap = response.data.data.nama_lengkap;
          this.form.nama_panggilan = response.data.data.nama_panggilan;
          this.form.no_handphone = response.data.data.no_handphone;
          this.form.no_handphone = response.data.data.no_handphone;
          this.form.email_pendukung = response.data.data.email_pendukung;
          this.form.nomor_identitas = response.data.data.nomor_identitas;
          this.form.kabupaten = response.data.data.kabupaten;
          this.form.kecamatan = response.data.data.kecamatan;
          this.form.kecamatan = response.data.data.kecamatan;
          this.form.kelurahan = response.data.data.kelurahan;
          this.form.rw = response.data.data.rw;
          this.form.tps = response.data.data.tps;
          this.form.suku = response.data.data.suku;
          this.form.level = response.data.data.level;
          this.form.foto_lama = response.data.data.foto;
          this.cropImg = response.data.data.link_foto;
          if (response.data.data.tim_relawan_tps == "Ya") {
            this.form.tim_relawan_tps = true;
          } else {
            this.form.tim_relawan_tps = false;
          }

          this.getReferal(this.form.no_pendukung);
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getReferal(id) {
      axios
        .get("/api/profil/referal/" + id)
        .then((response) => {
          this.referal = response.data.data.jumlah;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    confirmCard(id) {
      Swal.fire({
        title: "Apakah Anda Akan Mengunduh Kartu Anggota ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya!",
      }).then((result) => {
        if (result.value) {
          this.downloadCard(id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Dibatalkan", "", "error");
        }
      });
    },
    downloadCard(id) {
      try {
        axios
          .get("/api/profil/card/" + id)
          .then((response) => {
            if (response.status === 200) {
              const imageUrl = response.data.card;
              fetch(imageUrl)
                .then((response) => response.blob())
                .then((blob) => {
                  const url = window.URL.createObjectURL(new Blob([blob]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "kartu_anggota.png");
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>