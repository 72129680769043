import Vue from "vue";
import VueRouter from "vue-router";

import axios from "axios";
import Swal from "sweetalert2";

Vue.use(VueRouter);

import Dashboard from "../pages/Dashboard.vue";
import Login from "../pages/Login.vue";
import Register from "../pages/Register.vue";

import Profil from "../pages/profil/Profil.vue";

import Pendukung from "../pages/pendukung/Index.vue";
import FormPendukung from "../pages/pendukung/Form.vue";
import DetailPendukung from "../pages/pendukung/Detail.vue";

import Tiket from "../pages/tiket/Index.vue";
import FormTiket from "../pages/tiket/Form.vue";
import DetailTiket from "../pages/tiket/Detail.vue";

import Pengumuman from "../pages/pengumuman/Index.vue";
import FormPengumuman from "../pages/pengumuman/Form.vue";
import DetailPengumuman from "../pages/pengumuman/Detail.vue";

import Birthday from "../pages/birthday/Index.vue";
import FormBirthday from "../pages/birthday/Form.vue";

import Leaderboard from "../pages/leaderboard/Index.vue";
import DetailLeaderboard from "../pages/leaderboard/Detail.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard/",
  },
  { path: "*", redirect: "/dashboard/" },
  { path: "/dashboard", component: Dashboard },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  {
    path: "/pendukung",
    component: Pendukung,
    meta: {
      requiredLevel: ["Admin", "Operator"],
    },
  },
  {
    path: "/pendukung/create",
    component: FormPendukung,
    meta: {
      requiredLevel: ["Admin", "Operator"],
    },
  },
  {
    path: "/pendukung/update/:id",
    component: FormPendukung,
    meta: {
      requiredLevel: ["Admin", "Operator"],
    },
  },
  {
    path: "/pendukung/detail/:id",
    component: DetailPendukung,
    meta: {
      requiredLevel: ["Admin", "Operator"],
    },
  },
  {
    path: "/profil",
    component: Profil,
    meta: {
      requiredLevel: ["Admin", "Operator", "User"],
    },
  },
  {
    path: "/profil/:page",
    component: Profil,
    meta: {
      requiredLevel: ["Admin", "Operator", "User"],
    },
  },
  {
    path: "/tiket",
    component: Tiket,
    meta: {
      requiredLevel: ["Admin", "Operator", "User"],
    },
  },
  {
    path: "/tiket/create",
    component: FormTiket,
    meta: {
      requiredLevel: ["Admin", "Operator", "User"],
    },
  },
  {
    path: "/tiket/update/:id",
    component: FormTiket,
    meta: {
      requiredLevel: ["Admin", "Operator", "User"],
    },
  },
  {
    path: "/tiket/detail/:id",
    component: DetailTiket,
    meta: {
      requiredLevel: ["Admin", "Operator", "User"],
    },
  },
  {
    path: "/pengumuman",
    component: Pengumuman,
    meta: {
      requiredLevel: ["Admin", "Operator"],
    },
  },
  {
    path: "/pengumuman/create",
    component: FormPengumuman,
    meta: {
      requiredLevel: ["Admin", "Operator"],
    },
  },
  {
    path: "/pengumuman/update/:id",
    component: FormPengumuman,
    meta: {
      requiredLevel: ["Admin", "Operator"],
    },
  },
  {
    path: "/pengumuman/detail/:id",
    component: DetailPengumuman,
    meta: {
      requiredLevel: ["Admin", "Operator", "User"],
    },
  },
  {
    path: "/birthday-text",
    component: Birthday,
    meta: {
      requiredLevel: ["Admin"],
    },
  },
  {
    path: "/birthday-text/create",
    component: FormBirthday,
    meta: {
      requiredLevel: ["Admin"],
    },
  },
  {
    path: "/birthday-text/update/:id",
    component: FormBirthday,
    meta: {
      requiredLevel: ["Admin"],
    },
  },
  {
    path: "/leaderboard",
    component: Leaderboard,
    meta: {
      requiredLevel: ["Admin"],
    },
  },
  {
    path: "/leaderboard/detail/:id",
    component: DetailLeaderboard,
    meta: {
      requiredLevel: ["Admin"],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("user");
  const userAceess = JSON.parse(isLoggedIn);

  const userLevel = userAceess ? userAceess.level : null;
  const requiredLevel = to.meta.requiredLevel;

  if (to.path !== "/login" && !isLoggedIn && to.path !== "/register") {
    next("/login");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/dashboard");
  } else if (requiredLevel && userLevel && userLevel !== requiredLevel) {
    if (!requiredLevel.includes(userLevel)) {
      next("/forbidden"); // Tambahkan halaman forbidden jika level pengguna tidak sesuai
    } else {
      next();
    }
  } else {
    next();
  }

  const userData = localStorage.getItem("user");
  if (userData) {
    const user = JSON.parse(userData);
    const username = user.username;

    const formData = new FormData();
    formData.append("username", username);
    axios
      .post("/api/auth/cekStatus/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.status === false) {
          Swal.fire({
            title: "Info",
            text: "Session Habis, Silahkan Login Kembali",
            icon: "info",
            showCancelButton: false,
            showConfirmButton: false,
          });
          setTimeout(() => {
            Swal.close();
            localStorage.removeItem("user");
            if (to.path !== "/login") {
              next("/login");
            }
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
});

export default router;
