<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel panel-primary">
          <div class="panel-heading">
            <h3 class="panel-title bold">Form</h3>
          </div>
          <form
            @submit.prevent="postData"
            id="formPengumuman"
            enctype="multipart/form-data"
          >
            <div class="panel-body">
              <div class="row">
                <input
                  v-model="form.id_pengumuman"
                  type="text"
                  hidden
                  name="id_pengumuman"
                  class="form-control m-b-5"
                />
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="suku">Suku</label>
                    <select
                      v-model="form.suku"
                      name="suku"
                      class="form-control m-b-5"
                      :class="{
                        'is-invalid': formValidate.suku && !form.suku,
                      }"
                    >
                      <option value="">Pilih</option>
                      <option
                        v-for="suku in opsiSuku"
                        :key="suku.nama_suku"
                        :value="suku.nama_suku"
                      >
                        {{ suku.nama_suku }}
                      </option>
                      <option value="Lain-Lain">Lain-Lain</option>
                    </select>
                    <div
                      class="text-danger"
                      v-if="formValidate.suku && !form.suku"
                      v-html="formValidate.suku"
                    ></div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="title_birthday">Title</label>
                    <input
                      v-model="form.title_birthday"
                      type="text"
                      name="title_birthday"
                      class="form-control m-b-5"
                      :class="{
                        'is-invalid':
                          formValidate.title_birthday && !form.title_birthday,
                      }"
                    />
                    <div
                      class="text-danger"
                      v-if="formValidate.title_birthday && !form.title_birthday"
                      v-html="formValidate.title_birthday"
                    ></div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="text_birthday">Text</label>
                    <quill-editor
                      v-model="form.text_birthday"
                      :options="editorOption"
                      class="m-b-5"
                      :style="editorStyles"
                      :class="{
                        editorError:
                          formValidate.text_birthday && !form.text_birthday,
                      }"
                    ></quill-editor>
                    <div
                      class="text-danger"
                      v-if="formValidate.text_birthday && !form.text_birthday"
                      v-html="formValidate.text_birthday"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel-footer">
              <button
                type="button"
                class="btn btn-success mt-4"
                @click="postData()"
                :disabled="this.loading"
              >
                <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
                {{ $route.params.id ? "Update" : "Submit" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.editorError {
  border: 0.5px solid #ff5b57;
}
</style>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      editorStyles: {
        // border: "1px solid red",
        // height: "200px",
        // marginBottom: "80px",
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["link"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["blockquote"],
            [{ header: 1 }, { header: 2 }],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
      form: {
        id_birthday_text: "",
        suku: "",
        title_birthday: "",
        text_birthday: "",
      },
      file: "",
      link: "/api/birthdaytext/create/",
      formValidate: [],
      opsiSuku: [],
      loading: false,
    };
  },
  mounted() {
    this.getSuku();
    const id = this.$route.params.id;
    if (id) {
      this.form.id_birthday_text = id;
      this.link = "/api/birthdaytext/update/";
      this.fetchData();
    } else {
      this.form.id_birthday_text = "";
    }
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();
      formData.append("id_birthday_text", this.form.id_birthday_text);
      formData.append("suku", this.form.suku);
      formData.append("title_birthday", this.form.title_birthday);
      formData.append("text_birthday", this.form.text_birthday);
      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              this.$router.push("/birthday-text");
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      axios
        .get("/api/birthdaytext/detail/" + this.form.id_birthday_text)
        .then((response) => {
          this.form.id_birthday_text = response.data.data.id_birthday_text;
          this.form.suku = response.data.data.suku;
          this.form.title_birthday = response.data.data.title_birthday;
          this.form.text_birthday = response.data.data.text_birthday;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getSuku() {
      axios
        .get("/api/suku")
        .then((response) => {
          this.opsiSuku = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>

