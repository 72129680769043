<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title bold">Daftar Aspirasi</h3>
          <div class="panel-heading-btn">
            <a
              href="javascript:;"
              class="btn btn-xs btn-icon btn-circle btn-success"
              v-on:click="fetchData()"
              ><i class="fa fa-redo"></i
            ></a>
          </div>
        </div>
        <div class="panel-body">
          <router-link
            to="/tiket/create"
            class="btn btn-primary btn-block mb-2 d-md-none"
            v-if="level == 'User'"
          >
            <span>Kirim Aspirasi Anda</span>
          </router-link>
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :lineNumbers="true"
            :search-options="{
              enabled: true,
              placeholder: 'Search this table',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
              perPageDropdownEnabled: false,
              position: 'bottom',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }"
          >
            <div slot="table-actions">
              <router-link
                to="/tiket/create"
                class="btn btn-primary d-none d-md-block"
                v-if="level == 'User'"
              >
                <i class="fa fa-plus d-md-none"></i>
                <span class="d-none d-md-block">Kirim Aspirasi Anda</span>
              </router-link>
              <router-link to="/tiket/create" class="btn btn-primary" v-else>
                <i class="fa fa-plus d-md-none"></i>
                <span class="d-none d-md-block">Buat Tiket</span>
              </router-link>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'status'">
                <span
                  class="btn btn-xs btn-primary"
                  v-if="props.row.status_tiket == '0'"
                  >Open</span
                >
                <span
                  class="btn btn-xs btn-success"
                  v-if="props.row.status_tiket == '1'"
                  >Solve</span
                >
              </span>
              <span v-if="props.column.field == 'action'">
                <router-link
                  :to="'/tiket/detail/' + props.row.id_tiket"
                  class="btn btn-sm btn-success m-r-5"
                >
                  <i class="fa fa-search"></i>
                </router-link>
                <!-- <router-link
                  :to="'/tiket/update/' + props.row.id_tiket"
                  class="btn btn-sm btn-warning m-r-5"
                >
                  <i class="fa fa-pencil-alt"></i>
                </router-link> -->
                <button
                  class="btn btn-sm btn-danger"
                  v-on:click="confirmDelete(props.row.id_tiket)"
                >
                  <i class="fa fa-trash-alt"></i>
                </button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      columns: [
        {
          label: "Kode Tiket",
          field: "kode_tiket",
          width: "140px",
          thClass: "text-nowrap text-center",
          tdClass: "text-nowrap text-center",
        },
        {
          label: "Pembuat",
          field: "nama_lengkap",
          width: "210px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Subjek",
          field: "subjek",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Status",
          field: "status",
          width: "100px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "50px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      form: {
        level: "",
      },
      link: "/api/tiket/",
      nik: null,
      level: "",
    };
  },
  created() {
    const user = localStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user);
      this.level = this.user.level;
    } else {
      // this.$router.push("/login");
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      // this.rows = [];
      const user = localStorage.getItem("user");
      this.link = "/api/tiket/";
      if (user) {
        this.user = JSON.parse(user);
        if (this.user.level == "User") {
          this.link = this.link + this.user.nik;
        }
      } else {
        //
      }
      axios
        .get(this.link)
        .then((response) => {
          // console.log(response.data.data);
          this.rows = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    editRow(id) {
      window.alert(id);
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/api/tiket/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                showConfirmButton: false,
              });
              setTimeout(() => {
                Swal.close();
                this.fetchData();
              }, 700);
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
      // window.alert("Hapus Data dengan id: " + id);
    },
  },
};
</script>