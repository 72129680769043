<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title bold">Birthday Text</h3>
          <div class="panel-heading-btn">
            <a
              href="javascript:;"
              class="btn btn-xs btn-icon btn-circle btn-success"
              v-on:click="fetchData()"
              ><i class="fa fa-redo"></i
            ></a>
          </div>
        </div>
        <div class="panel-body">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :lineNumbers="true"
            :search-options="{
              enabled: true,
              placeholder: 'Search this table',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
              perPageDropdownEnabled: false,
              position: 'bottom',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }"
          >
            <div slot="table-actions">
              <router-link to="/birthday-text/create" class="btn btn-primary">
                <i class="fa fa-plus d-md-none"></i>
                <span class="d-none d-md-block">Text Baru</span>
              </router-link>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <router-link
                  :to="'/birthday-text/update/' + props.row.id_birthday_text"
                  class="btn btn-sm btn-warning m-r-5"
                >
                  <i class="fa fa-pencil-alt"></i>
                </router-link>
                <button
                  class="btn btn-sm btn-danger"
                  v-on:click="confirmDelete(props.row.id_birthday_text)"
                >
                  <i class="fa fa-trash-alt"></i>
                </button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      columns: [
        {
          label: "Suku",
          field: "suku",
          width: "120px",
          thClass: "text-nowrap text-center",
          tdClass: "text-nowrap text-center",
        },
        {
          label: "Title",
          field: "title_birthday",
          thClass: "text-center text-nowrap",
          tdClass: "text-wrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "150px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      form: {
        level: "",
      },
      link: "/api/birthdaytext/",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      // this.rows = [];

      axios
        .get(this.link)
        .then((response) => {
          // console.log(response.data.data);
          this.rows = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    editRow(id) {
      window.alert(id);
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/api/birthdaytext/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                showConfirmButton: false,
              });
              setTimeout(() => {
                Swal.close();
                this.fetchData();
              }, 700);
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>