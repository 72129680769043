var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"panel panel-primary"},[_c('div',{staticClass:"panel-heading"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("Birthday Text")]),_c('div',{staticClass:"panel-heading-btn"},[_c('a',{staticClass:"btn btn-xs btn-icon btn-circle btn-success",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.fetchData()}}},[_c('i',{staticClass:"fa fa-redo"})])])]),_c('div',{staticClass:"panel-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"lineNumbers":true,"search-options":{
            enabled: true,
            placeholder: 'Search this table',
          },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 10,
            perPageDropdownEnabled: false,
            position: 'bottom',
            nextLabel: '',
            prevLabel: '',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All',
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('router-link',{staticClass:"btn btn-sm btn-warning m-r-5",attrs:{"to":'/birthday-text/update/' + props.row.id_birthday_text}},[_c('i',{staticClass:"fa fa-pencil-alt"})]),_c('button',{staticClass:"btn btn-sm btn-danger",on:{"click":function($event){return _vm.confirmDelete(props.row.id_birthday_text)}}},[_c('i',{staticClass:"fa fa-trash-alt"})])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/birthday-text/create"}},[_c('i',{staticClass:"fa fa-plus d-md-none"}),_c('span',{staticClass:"d-none d-md-block"},[_vm._v("Text Baru")])])],1)])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }