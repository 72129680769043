<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title bold">Daftar Pengumuman</h3>
          <div class="panel-heading-btn">
            <a
              href="javascript:;"
              class="btn btn-xs btn-icon btn-circle btn-success"
              v-on:click="fetchData()"
              ><i class="fa fa-redo"></i
            ></a>
          </div>
        </div>
        <div class="panel-body">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :lineNumbers="true"
            :search-options="{
              enabled: true,
              placeholder: 'Search this table',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
              perPageDropdownEnabled: false,
              position: 'bottom',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }"
          >
            <div slot="table-actions">
              <router-link to="/pengumuman/create" class="btn btn-primary">
                <i class="fa fa-plus d-md-none"></i>
                <span class="d-none d-md-block">Buat Pengumuman</span>
              </router-link>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'status'">
                <div class="switcher">
                  <input
                    type="checkbox"
                    :name="props.row.id_pengumuman"
                    :id="props.row.id_pengumuman"
                    :checked="parseInt(props.row.tampil)"
                    v-on:click="
                      showAnnounce(props.row.id_pengumuman, props.row.tampil)
                    "
                  />
                  <label :for="props.row.id_pengumuman"></label>
                </div>
              </span>
              <span v-if="props.column.field == 'tanggal'">
                {{ formatDateTime(props.row.tanggal_pengumuman) }}
              </span>
              <span v-if="props.column.field == 'action'">
                <router-link
                  :to="'/pengumuman/detail/' + props.row.id_pengumuman"
                  class="btn btn-sm btn-success m-r-5"
                >
                  <i class="fa fa-search"></i>
                </router-link>
                <!-- <router-link
                  :to="'/pengumuman/update/' + props.row.id_pengumuman"
                  class="btn btn-sm btn-warning m-r-5"
                >
                  <i class="fa fa-pencil-alt"></i>
                </router-link> -->
                <button
                  class="btn btn-sm btn-danger"
                  v-on:click="confirmDelete(props.row.id_pengumuman)"
                >
                  <i class="fa fa-trash-alt"></i>
                </button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  data() {
    return {
      columns: [
        {
          label: "Tanggal",
          field: "tanggal",
          width: "120px",
          thClass: "text-nowrap text-center",
          tdClass: "text-nowrap text-center",
        },
        {
          label: "Judul",
          field: "judul_pengumuman",
          thClass: "text-center text-nowrap",
          tdClass: "text-wrap",
        },
        {
          label: "Status",
          field: "status",
          width: "100px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "150px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      form: {
        level: "",
      },
      link: "/api/pengumuman/",
      nik: null,
      isChecked: true,
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      // this.rows = [];
      const user = localStorage.getItem("user");
      this.link = "/api/pengumuman/";
      if (user) {
        this.user = JSON.parse(user);
        if (this.user.level == "User") {
          this.link = this.link + this.user.nik;
        }
      } else {
        //
      }
      axios
        .get(this.link)
        .then((response) => {
          // console.log(response.data.data);
          this.rows = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    editRow(id) {
      window.alert(id);
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/api/pengumuman/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                showConfirmButton: false,
              });
              setTimeout(() => {
                Swal.close();
                this.fetchData();
              }, 700);
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
      // window.alert("Hapus Data dengan id: " + id);
    },
    showAnnounce(id, tampil) {
      var toggleLink = "/api/pengumuman/toggleOn/" + id;
      console.log(tampil);
      if (tampil == 1) {
        toggleLink = "/api/pengumuman/toggleOff/" + id;
      }
      try {
        axios
          .get(toggleLink)
          .then((response) => {
            console.log(response.status);
            if (response.status === 201) {
              this.fetchData();
              // this.isChecked = true;
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    formatDateTime(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
  },
};
</script>