<template>
  <div class="row">
    <div class="col-md-8">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h3 class="panel-title bold">{{ tiket.subjek }}</h3>
          <div class="panel-heading-btn">
            <a
              href="javascript:;"
              class="btn btn-sm btn-success"
              @click="replyTicketOn()"
              v-if="replyTicket == false"
            >
              <i class="fas fa-reply"></i>
              Reply
            </a>
            <a
              href="javascript:;"
              class="btn btn-sm btn-warning"
              @click="replyTicketOff()"
              v-if="replyTicket == true"
            >
              <i class="fas fa-times"></i>
              Cancel
            </a>
          </div>
        </div>
        <div class="panel-body">
          <div v-if="replyTicket">
            <form
              @submit.prevent="postData"
              id="formPeserta"
              enctype="multipart/form-data"
            >
              <div class="row">
                <input
                  v-model="form.id_tiket"
                  type="text"
                  hidden
                  name="id_tiket"
                  class="form-control m-b-5"
                />
                <input
                  v-model="form.kode_tiket"
                  type="text"
                  hidden
                  name="kode_tiket"
                  class="form-control m-b-5"
                />
                <input
                  v-model="form.nik"
                  type="text"
                  hidden
                  name="nik"
                  class="form-control m-b-5"
                />

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="pesan">Pesan</label>
                    <quill-editor
                      v-model="form.respon_tiket"
                      :options="editorOption"
                      :style="editorStyles"
                    ></quill-editor>
                  </div>
                </div>
                <div class="col-md-12">
                  <button
                    style="float: right"
                    type="button"
                    class="btn btn-success"
                    @click="postReplay()"
                    :disabled="this.loading"
                  >
                    <i
                      v-if="this.loading"
                      class="fas fa-circle-notch fa-spin"
                    ></i>
                    Post
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="panel panel-default"
        v-for="data in rows"
        :key="data.id_tiket_log"
        :value="data.id_tiket_log"
      >
        <div class="panel-body">
          <div class="row">
            <div class="col-md-12">
              <h4>{{ data.nama_lengkap }}</h4>
              <p>{{ formatDateTime(data.log_created_at) }}</p>
              <p v-html="data.respon_tiket"></p>
            </div>
          </div>
        </div>
      </div>

      <div class="panel panel-default">
        <div class="panel-body">
          <div class="row">
            <div class="col-md-12">
              <h4>{{ tiket.nama_lengkap }}</h4>
              <p>{{ formatDateTime(tiket.dibuat) }}</p>
              <p v-html="tiket.pesan"></p>
              <a
                class="btn btn-sm btn-primary"
                :href="tiket.file"
                target="_blank"
                ><i class="fa fa-image"></i> Lihat Gambar</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h3 class="panel-title bold">Detail Tiket</h3>
        </div>
        <div class="panel-body">
          <div class="row">
            <div class="col-md-12">
              <strong>Nomor Tiket</strong>
              <br />
              <p>{{ tiket.kode_tiket }}</p>
              <strong>Pengirim</strong>
              <br />
              <p>
                {{ tiket.nama_lengkap }}
                <router-link
                  :to="'/pendukung/detail/' + tiket.nik"
                  class="btn btn-xs btn-success m-r-5"
                >
                  <i class="fa fa-search"></i> Lihat Profil
                </router-link>
              </p>
              <strong>Status</strong>
              <br />
              <p>
                <span class="btn btn-xs btn-primary" v-if="tiket.status == '0'"
                  >Open</span
                >
                <span class="btn btn-xs btn-success" v-if="tiket.status == '1'"
                  >Solve</span
                >
              </p>
              <strong>Dibuat</strong>
              <br />
              <p>{{ formatDateTime(tiket.dibuat) }}</p>
              <strong>Terakhir Dibalas</strong>
              <br />
              <p>
                <span v-if="tiket.dibalas == null">-</span>
                <span v-if="tiket.dibalas != null">{{
                  formatDateTime(tiket.dibalas)
                }}</span>
              </p>
            </div>
            <div class="col-md-12">
              <span
                class="btn btn-block btn-sm btn-danger"
                v-if="tiket.status == '0'"
                v-on:click="toggleTicket(form.id_tiket)"
                >Tutup Tiket</span
              >
              <span
                class="btn btn-block btn-sm btn-warning"
                v-if="tiket.status == '1'"
                v-on:click="toggleTicket(form.id_tiket)"
                >Open Tiket</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import Swal from "sweetalert2";
import { quillEditor } from "vue-quill-editor";
import moment from "moment";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      editorStyles: {
        height: "200px",
        marginBottom: "80px",
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: 1 }, { header: 2 }],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
      form: {
        id_tiket: "",
        kode_tiket: "",
        respon_tiket: "",
        pesan: "",
        nik: "",
      },
      rows: [],
      tiket: {
        nik: "",
        nama_lengkap: "",
        kode_tiket: "",
        subjek: "",
        pesan: "",
        file: "",
        dibuat: "",
        dibalas: "",
        status: "",
      },
      formValidate: [],
      loading: false,
      replyTicket: false,
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },

  mounted() {
    const id = this.$route.params.id;
    this.form.id_tiket = id;
    this.fetchData();
    this.fetchUser();
  },
  methods: {
    formatDateTime(dateTime) {
      return moment(dateTime).format("D MMMM YYYY [pukul] HH.mm");
    },
    replyTicketOn() {
      this.replyTicket = true;
    },
    replyTicketOff() {
      this.replyTicket = false;
    },
    postReplay() {
      this.loading = true;
      const formData = new FormData();

      formData.append("respon_tiket", this.form.respon_tiket);
      formData.append("kode_tiket", this.form.kode_tiket);
      formData.append("id_tiket", this.form.id_tiket);
      formData.append("nik", this.form.nik);

      axios
        .post("/api/tiket/reply/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.replyTicket = false;
              this.form.respon_tiket = "";
              this.fetchData();
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchUser() {
      const user = localStorage.getItem("user");
      if (user) {
        this.user = JSON.parse(user);
        this.form.nik = this.user.nik;
      } else {
        //
      }
    },
    fetchData() {
      axios
        .get("/api/tiket/detail/" + this.form.id_tiket)
        .then((response) => {
          this.form.id_tiket = response.data.data.id_tiket;
          this.form.kode_tiket = response.data.data.kode_tiket;
          this.form.file_lama = response.data.data.file_lama;
          this.form.subjek = response.data.data.subjek;
          this.form.pesan = response.data.data.pesan;

          this.tiket.kode_tiket = response.data.data.kode_tiket;
          this.tiket.nama_lengkap = response.data.data.nama_lengkap;
          this.tiket.nik = response.data.data.nomor_identitas;
          this.tiket.subjek = response.data.data.subjek;
          this.tiket.pesan = response.data.data.pesan;
          this.tiket.file = response.data.data.link_dokumen;
          this.tiket.dibuat = response.data.data.created_at;
          this.tiket.dibalas = response.data.data.last_replay;
          this.tiket.status = response.data.data.status_tiket;
          this.fetchLog();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchLog() {
      axios
        .get("/api/tiket/log/" + this.form.kode_tiket)
        .then((response) => {
          this.rows = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    toggleTicket(id) {
      var toggleLink = "/api/tiket/toggleTicket/" + id;
      try {
        axios
          .get(toggleLink)
          .then((response) => {
            console.log(response.status);
            if (response.status === 201) {
              this.fetchData();
              // this.isChecked = true;
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

